<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" lg="12" md="12">
					<template>
						<s-crud 
							:config="config"
							:filter="filter"
							@rowSelected="rowSelected($event)"
							ref="AlbaranCrud"
							search-input
							no-full
							title="Registro de Albaran"
							height="auto"
						>
							<template v-slot:filter>
								<v-container>
									<v-row justify="center" style="margin-left: 5px">
										<v-col cols="6" md="6" lg="3">
											<s-date
												label="Initial date"
												v-model="filter.cDateInitial"
											></s-date>
										</v-col>
										<v-col cols="6" md="6" lg="3">
											<s-date
												label="End date"
												v-model="filter.cDateFin"
											></s-date>
										</v-col>

										<v-col cols="12" md="12" lg="3">
											<s-select-definition
												clearable
												:def="1230"
												label="Planta Procesadora"
												v-model="filter.TypeProcessingPlant"
											/>
										</v-col>
									</v-row>
									<v-row
										justify="center"
										style="margin-left: 5px"
									>
										<v-col cols="6" md="6" lg="3">
											<s-select-definition
												clearable
												:def="1172"
												label="Tipo Cultivo"
												v-model="filter.TypeCrop"
											/>
										</v-col>

										<v-col cols="6" md="6" lg="3">
											<s-select-definition
												clearable
												style=""
												:def="1173"
												label="Cultivo"
												v-model="filter.TypeCultive"
											/>
										</v-col>

										<v-col cols="12" md="12" lg="3">
											<s-select-variety
												clearable
												:cultiveID="filter.TypeCultive"
												label="Variedad"
												full
												v-model="filter.VrtID"
											/>
										</v-col>
										
									</v-row>

									<v-row style="margin-left: 5px">
										<v-col cols="6" sm="2">
											<v-btn
												style="color:white"
												small
												rounded
												block
												color="info"
												@click="openAlbaran()"
												:disabled="disabledBtn"
											>
												Albaran
											</v-btn>
										</v-col>
										<v-col cols="6" sm="2">
											<v-btn
												v-if="isLevelAdmin"
												style="color:white"
												small
												rounded
												block
												color="warning"
												@click="anulateAlbaran()"
												:disabled="disabledBtn"
											>
												Anular Albaran
											</v-btn>
										</v-col>

										<!-- <v-col
											cols="12"
											class="s-col-form"
											xs="4"
											sm="4"
											md="4"
										>
											<s-switch
												label="Filtrar todos"
												
												v-model="filter.All"
											></s-switch>
										</v-col> -->

										<!-- <v-col cols="6" sm="3"> </v-col>
										<v-col cols="6" sm="3"> </v-col> -->
									</v-row>
								</v-container>
							</template>

							<template v-slot:SecStatus="{ row }">
								<v-chip
									x-small
									:color="row.SecStatus == 1 ? 'success' : 'warning'"
								>
									{{ row.SecStatus == 1 ? "Si" : "No" }}
								</v-chip>
							</template>

							<template v-slot:LdhDiscardDamage="{ row }">
								<v-chip
									x-small
									:color="row.LdhDiscardDamage > 0 ? 'success' : 'error'"
								>
									{{ row.LdhDiscardDamage > 0 ? "Si" : "No" }}
								</v-chip>
							</template>

							<template v-slot:TypeAlertName="{ row }">
								<v-chip
									x-small
									:color="row.TypeAlertColor"
								>
									{{ row.TypeAlertName }}
								</v-chip>
							</template>

							<template v-slot:AlbStatusSap="{ row }">
								<v-chip
									x-small
									:color="row.AlbStatusSap == 1 ? 'error' : 'info'"
								>
									{{ row.AlbStatusSap == 1 ? 'Sin Subir' : 'Subido' }}
								</v-chip>
							</template>

							<template v-slot:options>
								<div>
									<v-btn
										v-if="$fun.isAdmin()"
										x-small 
										color="info" 
										@click="enabledLot()"
									>	
										<i class="far fa-stop-circle"></i>Habilitar Lote
									</v-btn>
								</div>
							</template>
							<template v-slot:RcfID="{ row }">
								 {{row.RcfID + "-" + row.RcfCode}}
							</template>
						</s-crud>
					</template>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog
			v-model="openDialog"
			v-if="openDialog"
			scrollable
			
			persistent
			fullscreen
			transition="dialog-bottom-transition"
		>
			<create-albaran
				:RcfID="items.RcfID"
				:TypeCultive="items.TypeCultive"
				:item="items"
				@close="close()"
			>
			</create-albaran>
		</v-dialog>
	</div>
</template>
<script>
	import _sAlbaranService from "../../../services/FreshProduction/PrfCreateAlbaranService";
	import CreateAlbaran from "@/views/FreshProduction/CreateAlbaran/CreateAlbaran.vue";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	
	export default {
		components: {
			CreateAlbaran,
			SSelectVariety,
		},
		data: () => ({
			filterOne: 0,
			isLevelAdmin: false,
			items: {},
			openDialog: false,
			filter: {
				cDateInitial: null,
				cDateFin: null,
				TypeProcessingPlant: null,
				TypeCrop: null,
				VrtID: null,
				TypeCultive: null,
				/* All: 1, */
			},
			config: {
				service: _sAlbaranService,
				model: {
					RcfID: "ID",
					SecStatus: "",
					TypeAlertName: "",
					LdhDiscardDamage: "",
					AlbStatusSap : ""
				},
				headers: [
					{ text: "Albaran", value: "SecStatus", width: 100, align: "center"},
					{ text: "Muestreo daños", value: "LdhDiscardDamage", width: 100, align: "center" },
					{ text: "Nro. Lote Prod.", value: "RcfID", width: 160 },
					{ text: "Productor", value: "PrdCardNameResponsable", width: 360 },
					/* { text: "Productor Responsable Cobrar", value: "PrdCardNameResponsable", width: 360 }, */
					{ text: "Fundo", value: "FagName", width: 360 },
					{ text: "Lote", value: "FltName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
					{ text: "Variedad", value: "VrtName", width: 160 },
					{ text: "Nro. Jabas", value: "AmountJabasWeigh", width: 160 },
					{ text: "SAP", value: "AlbStatusSap", width: 160 },
					{ text: "Alerta", value: "TypeAlertName", width: 160 },
					{
						text: "Planta Procesadora",
						value: "TypeProcessingPlantName",
						width: 160,
					},
					{ text: "Empacadora", value: "TypeBalerName", width: 160 },
				],
			},

			disabledBtn: true,
		}),

		created() {
			if (this.$fun.getSecurity().IsLevelAdmin) {
				this.isLevelAdmin = true;
			} else {
				this.isLevelAdmin = false;
			}
		},

		methods: {
			close() {
				this.openDialog = false;
				this.$refs.AlbaranCrud.refresh();
			},
			rowSelected(val) {
				if(val.length > 0) {
					if (val !== undefined) {
						this.items = val[0];
						
						if(this.items.LdhDiscardDamage > 0){
							this.disabledBtn = false
						}else{
							this.disabledBtn = true
						}
					}
				}
				
			},

			isValidated() {
				var isValidated = true;
				if (this.items == undefined) {
					this.$fun.alert("No existen registro", "warning");
					isValidated = false;
					return;
				}

				if (this.items.length == 0) {
					this.$fun.alert("No existen registro", "warning");
					isValidated = false;
					return;
				}

				if (this.items.RcfID == 0) {
					this.$fun.alert("No se ha seleccionado registro", "warning");
					isValidated = false;
					return;
				}

				return isValidated;
			},
			
			openAlbaran() {
				if (!this.isValidated()) {
					return;
				}
				this.openDialog = true;
			},

			/* filterAll(val) {
				//alert(val)
				@input="filterAll($event)"
				this.filter = { All: this.filterOne };
				console.log("Filtrt todo ", this.filter);
			}, */

			anulateAlbaran() {
				
				if (!this.isValidated()) {
					return;
				}

				if (this.items.AlbID == 0) {
					this.$fun.alert(
						"Upps.. No se ha registrado albaran para el Lote de Producción seleccionado",
						"warning",
					);
					return;
				}
				if (this.items.AlbStatusSap == 2) {
					this.$fun.alert(
						"No se puede anular, Albaran ya fue subio a SAP",
						"warning",
					);
					return;
				}

				this.$fun
					.alert(
						"Esta seguro de Anular **ALBARAN** ? al Fundo: " +
							this.items.FagName,
						"question",
					)
					.then(val => {
						if (val.value) {
							_sAlbaranService
								.anulateAlbaran(this.items, this.$fun.getUserID())
								.then(r => {
									if ((r.status = 200)) {
										this.$fun.alert(
											"Albaran anulado correctamente",
											"success",
										);
										this.$refs.AlbaranCrud.refresh();
									}
								});
						}
					});
			},


			enabledLot(){
				if (this.items.AlbStatusSap == 2) {

					this.items.enabledSAP = 10
					

					this.$fun
					.alert(
						"Seguro de Habilitar LP para reingreso a SAP ?" +
							this.items.FagName,
						"question",
					)
					.then(val => {
						if (val.value) {
							
							_sAlbaranService
								.save(this.items, this.$fun.getUserID())
								.then(r => {
									if ((r.status = 200)) {
										this.$fun.alert(
											"Albaran anulado correctamente",
											"success",
										);
										this.$refs.AlbaranCrud.refresh();
									}
								});
						}
					});

				}else{
					this.$fun.alert(
											"LP aun no se a migrado a SAP",
											"warning",
										);
				}
			},
		},
	};
</script>
